var _global = typeof globalThis !== "undefined" ? globalThis : typeof self !== "undefined" ? self : global;

var exports = {};
exports = ProtoList;

function setProto(obj, proto) {
  if (typeof Object.setPrototypeOf === "function") return Object.setPrototypeOf(obj, proto);else obj.__proto__ = proto;
}

function ProtoList() {
  (this || _global).list = [];
  var root = null;
  Object.defineProperty(this || _global, "root", {
    get: function () {
      return root;
    },
    set: function (r) {
      root = r;

      if ((this || _global).list.length) {
        setProto((this || _global).list[(this || _global).list.length - 1], r);
      }
    },
    enumerable: true,
    configurable: true
  });
}

ProtoList.prototype = {
  get length() {
    return (this || _global).list.length;
  },

  get keys() {
    var k = [];

    for (var i in (this || _global).list[0]) k.push(i);

    return k;
  },

  get snapshot() {
    var o = {};

    (this || _global).keys.forEach(function (k) {
      o[k] = this.get(k);
    }, this || _global);

    return o;
  },

  get store() {
    return (this || _global).list[0];
  },

  push: function (obj) {
    if (typeof obj !== "object") obj = {
      valueOf: obj
    };

    if ((this || _global).list.length >= 1) {
      setProto((this || _global).list[(this || _global).list.length - 1], obj);
    }

    setProto(obj, (this || _global).root);
    return (this || _global).list.push(obj);
  },
  pop: function () {
    if ((this || _global).list.length >= 2) {
      setProto((this || _global).list[(this || _global).list.length - 2], (this || _global).root);
    }

    return (this || _global).list.pop();
  },
  unshift: function (obj) {
    setProto(obj, (this || _global).list[0] || (this || _global).root);
    return (this || _global).list.unshift(obj);
  },
  shift: function () {
    if ((this || _global).list.length === 1) {
      setProto((this || _global).list[0], (this || _global).root);
    }

    return (this || _global).list.shift();
  },
  get: function (key) {
    return (this || _global).list[0][key];
  },
  set: function (key, val, save) {
    if (!(this || _global).length) this.push({});
    if (save && (this || _global).list[0].hasOwnProperty(key)) this.push({});
    return (this || _global).list[0][key] = val;
  },
  forEach: function (fn, thisp) {
    for (var key in (this || _global).list[0]) fn.call(thisp, key, (this || _global).list[0][key]);
  },
  slice: function () {
    return (this || _global).list.slice.apply((this || _global).list, arguments);
  },
  splice: function () {
    // handle injections
    var ret = (this || _global).list.splice.apply((this || _global).list, arguments);

    for (var i = 0, l = (this || _global).list.length; i < l; i++) {
      setProto((this || _global).list[i], (this || _global).list[i + 1] || (this || _global).root);
    }

    return ret;
  }
};
export default exports;